import * as React from "react"
import Layout from "../components/layout"
import { Seo } from "../components/seo"
import RegistrationMain from "../components/registration"
import {graphql} from 'gatsby';
import { useTranslation } from "react-i18next";

const RegistrationPage = () => {
  const { t } = useTranslation();

  return(
    <Layout>
        <Seo 
        title={t('siteTitle')}
        description={t('siteDescription')}
      />
      <RegistrationMain />
    </Layout>
  )
}

export default RegistrationPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {ns: {in: ["index"]}, language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;